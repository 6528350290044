<template>
  <trazo-off-canvas
    :isOpen="isOpen"
    id="modal-form-user"
    :selectorToggle="selectorToggle"
    v-on:close="closeModal"
    :title="textModal"
  >
    <template v-slot:header-buttons>
      <b-button size="sm" variant="light" @click="closeModal">
        <b-icon icon="arrow-counterclockwise" />
      </b-button>
      <b-button size="sm" variant="light" @click="onSubmit" :disabled="sending">
        {{ sending ? "Guardando..." : "Guardar" }}
      </b-button>
    </template>
    <div class="justify-content-center">
      <form class="full" id="formSubproject" ref="formSubproject">
        <b-alert
          show
          variant="danger"
          v-bind:key="error"
          v-for="error in errors"
        >{{ error }}
        </b-alert
        >
        <trazo-form-input
          id="input-first_name"
          v-model="first_name"
          label="Nombre"
          name="first_name"
          :required="true"
          labelPosition="top"
          type="text"
        />


        <trazo-form-input
          id="input-username"
          v-model="username"
          name="username"
          label="Usuario"
          :required="true"
          labelPosition="top"
          type="text"
        />
        <trazo-form-input
          id="input-email"
          v-model="email"
          label="Correo electrónico"
          name="email"
          :required="true"
          labelPosition="top"
          type="email"
        />
      </form>
    </div>
  </trazo-off-canvas>
</template>

<script>
import Vue from "vue";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";
import ApiService from "@/core/services/api.service";
import TrazoFormInput from "@/view/content/TrazoFormInput";
import TrazoOffCanvas from "@/view/content/TrazoOffCanvas";
import ImageCompressor from "compressorjs";

function initialState() {
  return {
    passwordFieldType: "password",
    showImage: false,
    textModal: "NUEVO USUARIO",
    username: "",
    scanned_signature: "",
    first_name: "",
    last_name: "",
    last_name_2: "",
    email: "",
    password: Math.random()
      .toString(36)
      .substring(7),
    driver_license_type: "-",
    license_number: "",
    id: "",
    edit: false,
    errors: [],
    sending: false,
    driver_license_types: [
      { value: "-", text: "NO POSEE" },
      { value: "A-I", text: "A-I" },
      { value: "A-IIa", text: "A-IIa" },
      { value: "A-IIb", text: "A-IIb" },
      { value: "A-IIIa", text: "A-IIIa" },
      { value: "A-IIIb", text: "A-IIIb" },
      { value: "A-IIIc", text: "A-IIIc" },
      { value: "B-I", text: "B-I" },
      { value: "B-IIa", text: "B-IIa" },
      { value: "B-IIb", text: "B-IIb" },
      { value: "B-IIc", text: "B-IIc" }
    ],
    imageToSend: null
  };
}

export default Vue.component("modal-form-user", {
  data() {
    return initialState();
  },
  props: ["objForm", "isOpen", "selectorToggle"],
  watch: {
    objForm(newValue, oldValue) {
      if (newValue?.id != oldValue?.id) {
        this.setData();
      }
    }
  },
  methods: {
    onShowModal(event) {
      this.showImage = event;
    },
    selectPhoto(image) {
      // let data = new FormData();
      this.imageToSend = image;
      new ImageCompressor(image.blob, {
        convertSize: Infinity,
        maxWidth: 300,
        maxHeight: 100,
        minWidth: 300,
        minHeight: 100,
        quality: 0.6,

        success: result => {
          console.log("new file", result);
          this.imageToSend.blob = result;
        },
        error(err) {
          console.log(err.message);
        }
      });
    },
    closeModal() {
      this.resetForm();
      Object.assign(this.$data, initialState());
      this.$emit("close");
    },
    resetForm() {
      this.setupValidation();
      this.fv.off("core.form.valid", () => {});
      this.fv.off("core.form.invalid", () => {});
      this.fv.resetForm();
    },
    onSubmit() {
      this.resetForm();
      this.fv.validate();
      this.fv.on("core.form.valid", () => {
        const saveFunction = this.id ? ApiService.patch : ApiService.post;

        let objeSaved = new FormData();
        if (this.imageToSend) {
          objeSaved.append(
            "scanned_signature",
            this.imageToSend.blob,
            this.imageToSend.name
          );
        }
        objeSaved.append("username", this.username.trim());
        objeSaved.append("first_name", this.first_name.trim());
        objeSaved.append("email", this.email.trim());
        if (!this.edit) {
          objeSaved.append("password", this.password.trim());
        }
        this.errors = [];
        this.sending = true;
        saveFunction(`api/user/${this.id ? this.id + "/" : ""}`, objeSaved)
          .then(({ data }) => {
            this.$emit("toast", {
              message: `Se ha ${
                this.edit
                  ? "editado el usuario correctamente"
                  : "creado el usuario. Un enlace ha sido enviado su correo."
              }`,
              object: {
                title: `Éxito`,
                variant: "success",
                solid: true
              }
            });
            this.$emit(this.edit ? "updated" : "created", data);
            this.closeModal();
            this.sending = false;
          })
          .catch(({ response: { data } }) => {
            this.sending = false;
            Object.entries(data).forEach(object => {
              this.errors.push(object[1].join(" - "));
              this.fv.updateFieldStatus(object[0], "Invalid");
            });
            this.$emit("toast", {
              message: "Ha ocurrido un error",
              object: {
                title: `Error`,
                variant: "danger",
                solid: true
              }
            });
          });
      });
      this.fv.on("core.form.invalid", () => {
        this.$emit("toast", {
          message: "Por favor complete los campos correctamente.",
          object: {
            title: `Error`,
            variant: "danger",
            solid: true
          }
        });
      });
    },
    setData() {
      if (!this.custom_lodash.isEmpty(this.objForm)) {
        this.id = this.objForm.id;
        this.username = this.objForm.username;
        this.first_name = this.objForm.first_name;
        this.scanned_signature = this.objForm.scanned_signature;
        this.last_name = this.objForm.last_name;
        this.last_name_2 = this.objForm.last_name_2;
        this.driver_license_type = this.objForm.driver_license_type;
        this.license_number = this.objForm.license_number;
        this.email = this.objForm.email;
        this.textModal = "EDITAR USUARIO";
        this.edit = true;
      }
    },
    setupValidation() {
      const formSubproject = KTUtil.getById("formSubproject");
      this.fv = formValidation(formSubproject, {
        fields: {
          username: {
            validators: {
              notEmpty: {
                message: "El username es obligatorio"
              }
            }
          },
          email: {
            validators: {
              notEmpty: {
                message: "La correo electrónico es obligatorio"
              },
              emailAddress: {
                message: "No es un correo electrónico válido."
              }
            }
          },
          first_name: {
            validators: {
              notEmpty: {
                message: "El nombre es obligatorio"
              }
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          submitButton: new SubmitButton(),
          bootstrap: new Bootstrap()
        }
      });
    }
  },
  mounted() {
    this.setData();
    this.setupValidation();
  },
  components: {
    "trazo-form-input": TrazoFormInput,
    TrazoOffCanvas
  }
});
</script>
