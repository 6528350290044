<template>
  <div>
    <portal to="subheader_actions">
      <b-button v-b-modal.modal-form-user @click="openModal" class="mr-2">
        <b-icon icon="plus" aria-label="Help"></b-icon>
        Nuevo
      </b-button>
    </portal>
    <div class="row">
      <div class="col-md-12">
        <b-card header-tag="header" footer-tag="footer">
          <template v-slot:header>
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Escriba para buscar"
            ></b-form-input>
          </template>
          <b-card-text>
            <div>
              <b-table
                :filter="filter"
                @filtered="onFiltered"
                head-variant="dark"
                :items="items"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
              >
                <template #cell(actions)="data">
                  <div class="h5 mb-0">
                    <b-button
                      size="sm"
                      variant="light"
                      v-b-modal.modal-form-user
                      @click="editItem(data)"
                    >
                      <b-icon icon="pencil-square"></b-icon>
                    </b-button>
                    <b-button
                      @click="removeItem(data)"
                      size="sm"
                      variant="light"
                    >
                      <b-icon icon="trash-fill"></b-icon>
                    </b-button>
                  </div>
                </template>
              </b-table>
            </div>
          </b-card-text>
          <template v-slot:footer>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </template>
        </b-card>
      </div>
    </div>
    <modal-form-user
      :isOpen="showModal"
      v-show="loaded"
      v-on:close="
        showModal = false;
        currentObject = null;
      "
      :objForm="currentObject"
      v-on:updated="onUpdated"
      v-on:created="onCreated"
      v-on:toast="openToast"
    />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import ModalFormUser from "./ModalFormUser";

export default {
  data() {
    return {
      showModal: false,
      loaded: false,
      currentObject: {},
      currentPage: 1,
      perPage: 12,
      filter: null,
      items: [],
      rows: 0,
      fields: [
        { key: "actions", label: "Acciones" },
        {
          key: "full_name",
          sortable: true,
          label: "Nombres y Apellidos"
        },
        {
          key: "email",
          sortable: true,
          label: "Correo"
        }
      ]
    };
  },
  computed: {},
  methods: {
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
    openToast({ message, object }) {
      this.$bvToast.toast(message, object);
    },
    listItems() {
      ApiService.get("api/user", "").then(({ data }) => {
        this.items = data;
        this.rows = this.items.length;
      });
    },
    editItem(data) {
      this.currentObject = data.item;
      this.openModal();
    },
    openModal() {
      if (!this.loaded)
        setTimeout(() => {
          this.showModal = true;
        }, 50);
      else this.showModal = true;
      this.loaded = true;
    },
    removeItem({ item: { id } }) {
      Swal.fire({
        title: "",
        text: "¿Está seguro de eliminar el usuario?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonClass: "btn btn-secondary",
        confirmButtonText: "Sí, estoy seguro.",
        confirmButtonClass: "btn btn-secondary",
        cancelButtonText: "Cancelar"
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          ApiService.delete(`/api/user/${id}/`)
            .then(() => {
              this.openToast({
                message: "Se elimino correctamente el usuario",
                object: {
                  title: `Éxito`,
                  variant: "success",
                  solid: true
                }
              });
              this.items = this.items.filter(el => el.id !== id);
              this.rows = this.items.length;
            })
            .catch(() => {
              this.openToast({
                message: "Ha ocurrido un error",
                object: {
                  title: `Error`,
                  variant: "danger",
                  solid: true
                }
              });
            });
        }
      });
    },
    onCreated(object) {
      this.items.unshift(object);
      this.rows = this.items.length;
    },
    onUpdated(object) {
      this.items = this.items.map(data => {
        const { id } = data;
        if (id === object.id) {
          return object;
        }
        return data;
      });
    }
  },
  created() {
    this.listItems();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Mentenimiento" },
      { title: "Usuario" }
    ]);
  },
  components: {
    ModalFormUser
  }
};
</script>
